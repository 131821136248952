<template>

  <h2 class="fs-5  mt-5">
    Converted File in multiple sizes, <br/>Tap the image to download
  </h2>

  <div class="row text-center mt-5">
    <div v-for="file in files" :key="file.path" @click="downloadFile(file)" class="col">
      <div  role="button" class="ratio ratio-4x3 imageBox">
        <img :src="filePathToUrl(file.path)" alt="" @error="$emit('imageError')">
      </div>
      <p>{{ file.size }}.{{ file.extension }}</p>
    </div>
  </div>

  <div class="row mt-3">
    <div class="col d-flex justify-content-center">
      <button type="button" class="btn btn-success" @click="downloadAllFiles(files)">Download All</button>
    </div>
  </div>

</template>

<script>
import axios from 'axios'

export default {
  props: ['files'],
  data() {
    return {
      api: process.env.VUE_APP_API
    }
  },
  methods: {
    filePathToUrl(filePath) {
      return `${this.api}${filePath.replace('.', '')}`
    },
    downloadFile(file) {
      axios({
        url: this.filePathToUrl(file.path),
        method: 'GET',
        responseType: 'blob',
      }).then((response) => {
        const fileURL = window.URL.createObjectURL(new Blob([response.data]));
        const fileLink = document.createElement('a');

        fileLink.href = fileURL;
        fileLink.setAttribute('download', `${file.size}.${file.extension}`);
        document.body.appendChild(fileLink);

        fileLink.click();
      });
    },
    downloadAllFiles(allFiles) {
      for (const file of allFiles) {
        this.downloadFile(file)
      }
    }
  }
}
</script>

<style lang="sass" scoped>
.imageBox
  border: 1px solid #ced4da
  border-radius: .5rem
  overflow: hidden
  background-color: #DDE0E3

  > img
    width: 100%
    height: 100%
    object-fit: contain
    object-position: center
</style>

