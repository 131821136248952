<template>
  <div class="alert" :class="{'alert-success': data.success, 'alert-danger': !data.success, 'alert-warning': data.success === 'medium'}" role="alert">
    {{ data.message }}
  </div>
</template>

<script>
export default {
  props: ['data']
}
</script>