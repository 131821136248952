<template>
  <div class="container my-5">
    <div class="is-flex is-justify-content-center mb-3">
      <h1 class="fs-5  mt-5 mb-3">
        WEBP Convertor for multiple resolutions
      </h1>
    </div>

    <ActionBanner v-if="banner.show" :data="banner"/>

    <section v-if="loading">
      <LoadingComponent/>
    </section>
    <section v-else>
      <UploadFile @handleUpload="handleUpload"/>
      <ConvertedFiles v-if="files.length > 0" :files="files" @imageError="handleImageError"/>
    </section>
  </div>
</template>

<script>
import UploadFile from './components/UploadFile'
import ConvertedFiles from './components/ConvertedFiles'
import ActionBanner from './components/ActionBanner'
import LoadingComponent from './components/LoadingComponent'
import axios from "axios";

export default {
  name: 'App',
  components: {UploadFile, ConvertedFiles, ActionBanner, LoadingComponent},
  data() {
    return {
      files: [],
      filesTimestamp: '',
      banner: {
        show: false,
        success: false,
        message: ''
      },
      loading: false,
    }
  },
  methods: {
    handleUpload(file) {
      this.loading = true
      const formData = new FormData()
      formData.append('photo', file)

      axios.post(`${process.env.VUE_APP_API}/upload-file`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }).then(response => {
        const {success, message, data, timestamp} = response.data
        setTimeout(() => {
          this.loading = false
          this.files = data
          sessionStorage.setItem('file', timestamp)
          this.banner = {
            show: true,
            success: success,
            message: message
          }
        }, 1500)

      })
          .catch(error => {
            const {success, message} = error.response.data
            this.loading = false
            this.banner = {
              show: true,
              success,
              message
            }
          })
    },
    handleImageError() {
      this.banner = {
        show: true,
        success: 'medium',
        message: 'You need to be patient. Your files are on the way.'
      }
      setTimeout(() => {
        location.reload()
      }, 5000)

    }
  },
  mounted() {
    const queryString = window.location.search
    const urlParams = new URLSearchParams(queryString)
    const removeUploadedFiles = urlParams.get('remove')
    if (removeUploadedFiles) {
      axios.delete(`${process.env.VUE_APP_API}/clean-files`)
          .then((response) => {
            const {success, message} = response.data
            this.banner = {
              show: true,
              success,
              message
            }
          })
    } else {
      const fileTimeStamp = sessionStorage.getItem('file')
      if (fileTimeStamp) {
        axios.get(`${process.env.VUE_APP_API}/upload-file?timestamp=${fileTimeStamp}`)
            .then(response => {
              const {success, message, data} = response.data
              this.files = data
              if (data.length > 0) {
                this.banner = {
                  show: true,
                  success,
                  message
                }
              }
            })
      }
    }
  }
}
</script>

